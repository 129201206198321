import qa from './qa.json';
import qaLocal from './qa-local.json';
import prod from './prod.json';
import previewProd from './preview-prod.json';

interface IConfig {
  engineName: string;
  endpointBase: string;
  searchKey: string;
  resultFields: {
    [key: string]: {
      raw: object;
      snippet?: {
        size: number;
        fallback: boolean;
      };
    };
  };
  sortFields: Array<{
    name: string;
    value: string;
    direction: string;
  }>;
  disjunctiveFacets: string[];
  facets: {
    [key: string]: {
      type: string;
      size: number;
    };
  };
  titleField: string;
  urlField: string;
  dataopsPreviewUsers: string[];
  dataopsOAuthClient: {
    clientId: string;
    authorizationEndpoint: string;
    tokenEndpoint: string;
    redirectUri: string;
    scope: string;
    decodeToken: boolean;
    redirectCallbackPath: string;
    autoLogin: boolean;
  };
  dataopsliveBaseUrl: string;
  dataopsCatalogApiDeployEndpoint: string;
  dataopsCatalogApiUpdateEndpoint: string;
  dataopsCatalogApiDeleteEndpoint: string;
  dataopsCatalogApi2Endpoint: string;
  dataopsCreateShareLinkEndpoint: string;
  dataopsShareEnvironment: ShareEnvironment;
}

export function getConfig(): IConfig {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return qaLocal as IConfig;
    case 'qa':
      return qa as IConfig;
    case 'preview':
      return previewProd as IConfig;
    case 'production':
      return prod as IConfig;
    default:
      throw new Error(`No config for environment "REACT_APP_ENV"`);
  }
}

export function getFacetFields() {
  return Object.keys(getConfig().facets);
}

type ShareEnvironment = 'dev' | 'qa' | 'production';
export function resolveShareUrlDomain(shareEnvironment: ShareEnvironment): string {
  switch (shareEnvironment) {
    case 'dev':
      return 'https://share.qa.dataops.live';
    case 'qa':
      return 'https://share.qa.dataops.live';
    case 'production':
      return 'https://share.dataops.live';
  }
}

export function isPreviewUser(userEmail: string): boolean {
  const users = getConfig().dataopsPreviewUsers;
  return users.some((email) => {
    if (email === '*@dataops.live') {
      return userEmail.endsWith('@dataops.live');
    }
    return userEmail === email;
  });
}
